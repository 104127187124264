//  Bordered table inside cards
.card-table.table-bordered {
    thead tr,
    thead th {
        border-top-width: 0;
    }

    thead th:first-child,
    tbody td:first-child {
        border-left-width: 0;
    }

    thead th:last-child,
    tbody td:last-child {
        border-right-width: 0;
    }

    tbody tr:last-child {
        border-bottom-width: 0;
    }
}

