// Scheduler table
.table-scheduler {
    th.date,
    td.date {
        min-width: $scheduler-date-cell-width;
        max-width: $scheduler-date-cell-width;
        width: $scheduler-date-cell-width;
    }

    .event {
        position: relative;
        padding: $scheduler-event-padding;
        margin-bottom: $scheduler-event-spacing;
        height: $scheduler-event-height;
        display: flex;
        flex-direction: column;
        border-radius: $border-radius;
        font-size: $small-font-size;
        color: $azure;
        background-color: tint-color($azure, 85%);
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }

        &.span-2 {
            width: ($scheduler-date-cell-width * 2) - 1rem;
        }

        &.span-3 {
            width: ($scheduler-date-cell-width * 3) - 1rem;
        }

        &.level-1 {
            margin-top: $scheduler-event-height + $scheduler-event-spacing;
        }

        &.level-2 {
            margin-top: ($scheduler-event-height * 2) + ($scheduler-event-spacing * 2);
        }
    }
}
