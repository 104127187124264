// Striped Table
$table-striped-bg: #fbfbfb;

// Scheduler table
$scheduler-date-cell-width: 10rem;
$scheduler-event-height: 3rem;
$scheduler-event-padding: 0.5rem;
$scheduler-event-spacing: 0.5rem;

// Timesheet table
$timesheet-member-cell-width: 10rem;
$timesheet-task-cell-width: 15rem;
$timesheet-date-cell-width: 5rem;
$timesheet-time-cell-width: $timesheet-date-cell-width;
