// Scheduler table
.table-timesheet {
    th.member {
        width: $timesheet-member-cell-width;
    }

    td .member {
        @include text-truncate;

        display: inline-block;
        width: $timesheet-member-cell-width;
    }

    th.task {
        width: $timesheet-task-cell-width;
    }

    td .task {
        @include text-truncate;

        display: inline-block;
        width: $timesheet-task-cell-width;
    }

    th.date {
        min-width: $timesheet-date-cell-width;
        max-width: $timesheet-date-cell-width;
        width: $timesheet-date-cell-width;
        text-align: center;

        .day, .weekday {
            display: block;
        }
    }

    td.time {
        min-width: $timesheet-time-cell-width;
        max-width: $timesheet-time-cell-width;
        width: $timesheet-time-cell-width;
        text-align: center;
    }

    tr td:last-child, tr th:last-child {
        padding-right: 0.5rem;
    }
}
