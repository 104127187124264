@import "./variables";

@import "~@tabler/core/src/scss/tabler.scss";
// @import "~@tabler/core/src/scss/vendor/_nouislider.scss";
@import "~@tabler/core/src/scss/vendor/_litepicker.scss";
@import "~@tabler/core/src/scss/vendor/_tom-select.scss";
@import "~@tabler/core/src/scss/vendor/_apexcharts.scss";
@import "~intl-tel-input/build/css/intlTelInput.css";

@import "./components/table";
@import "./components/scheduler";
@import "./components/timesheet";

@import "./vendors/intl-tel-input";

.dropdown {
    &-menu {
        &.show {
            transform: translate(0px, 8px) !important;
        }
        &-demo {
            &.show {
                transform: translate(1142px, 178px) !important;
            }
        }
        &.filter {
            &.show {
                transform: translate(0px, 39px) !important;
            }
        }
    }
}


.input{
    &-calender{
        width: 100%;
    }
}
